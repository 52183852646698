import React, { useEffect, useState, useRef } from "react"

import {
  Pannel,
  CloseButtn,
  Icon,
  ValidateButton,
} from "./FormulaireLivraison.style"
import { IStepInfo } from "../../../../core/config"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useAuth, useCheckout } from "@saleor/sdk"
import { IAddress } from "@saleor/sdk/lib/api/Checkout/types"
import { PointRelais } from "../../../../lib/map"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { FormAdresse } from "./PropFormAdresse"
import { changeshippingAdress } from "../../../../entities/shippingAddress/shippingAdress.reducer"
import { Spinner } from "../../../common/Spinner"

interface Props {
  onClickOutside?: Function
  stepInfo: IStepInfo
  setOpen?: Function
}

export interface IFormInput {
  zipcode: string
  city: string
}

export function FormulaireLivraisonView({
  stepInfo,
  setOpen = () => {},
}: Props) {
  // ************************** Reducer ****************************************************//
  const { selected: selectedId, pointLivraison } = useSelector(
    state => state.activePointLivraisonReducer
  )
  const { selected } = useSelector(state => state.shippingAdressReducer)

  // ******************************************************************************//
  const {
    availableShippingMethods,
    setShippingMethod,
    checkout,
    setShippingAddress,
  } = useCheckout()
  const { user } = useAuth()

  const { handleSubmit } = useForm<IFormInput>()
  const dispatch = useDispatch()
  const [isPointRelais, setIsPointRelais] = useState(false)
  const [shippingAsBilling, setShippingAsBilling] = useState(true)
  const [error, setError] = useState("")

  const isSelectedMethodAvailable = id => {
    let res = false
    availableShippingMethods?.map(item => {
      if (item.id == id) {
        res = true
      }
    })
    return res
  }

  useEffect(() => {
    if (availableShippingMethods) {
      if (availableShippingMethods.length > 0) {
        if (
          !checkout?.shippingMethod?.id ||
          !isSelectedMethodAvailable(checkout?.shippingMethod?.id)
        ) {
          let res = setDefaultShippingMethods()
          setShippingMethod(res.id)
        }
      }
    }
  }, [checkout, availableShippingMethods])

  useEffect(() => {
    if (selectedId && pointLivraison) {
      var pointSelect = pointLivraison as PointRelais
      let address: IAddress = {
        city: pointSelect.City,
        country: {
          code: pointSelect.Code,
          country: pointSelect.Country,
        },
        companyName: pointSelect.CompanyName,
        postalCode: pointSelect.PostalCode,
        streetAddress1: pointSelect.Adress,
        identifiant: pointSelect.identifiant,
      }
      //TODO : fusion des redux livraison et shipping adresses
      dispatch(changeshippingAdress(address))
    }
  }, [selectedId])

  useEffect(() => {
    if (shippingAsBilling && user?.defaultBillingAddress) {
      dispatch(changeshippingAdress(user.defaultBillingAddress as IAddress))
      setShippingAddress(user.defaultBillingAddress, user?.email)
    }
  }, [shippingAsBilling])

  // Envoi la requete a saleor
  const onSubmit = async () => {
    if (!selected || !user?.email) return

    const email = user?.email as string
    
    if (selected.identifiant) {
      selected.streetAddress2 = `${
        selected.streetAddress2 || ""
      } identifiant n°${selected.identifiant}`
      if (!selected.phone) {
        if (!user.defaultBillingAddress) {
          selected.phone = user.addresses[0]?.phone
        }
        else{
          selected.phone = user.defaultBillingAddress.phone
        }
      }
      if (!selected.firstName) {
        selected.firstName = user.firstName
      }
      if (!selected.lastName) {
        selected.lastName = user.lastName
      }
    }
    await setShippingAddress(selected, email)
  }

  const handleClick = () => {
    onSubmit()
    setOpen()
  }
  const isMobile = useDetectDevice()

  const [clickedOutside, setClickedOutside] = useState(false)
  const myRef = useRef()

  const handleClickOutside = e => {
    if (!myRef.current?.contains(e.target)) {
      setClickedOutside(true)
      handleClick()
    }
  }
  const handleClickInside = () => setClickedOutside(false)

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  })

  const setDefaultShippingMethods = () => {
    let res

    availableShippingMethods.map(item => {
      if (item.name != "Livraison en point relais") {
        res = item
      }
    })
    return res
  }

  if (!checkout?.shippingMethod?.id) {
    return <Spinner />
  }

  return (
    <Pannel ref={myRef} className={isMobile && "mobile"}>
      {!isMobile && (
        <CloseButtn onClick={handleClick}>
          <Icon icon="cross"></Icon>
        </CloseButtn>
      )}
      <FormAdresse
        shippingAsBilling={shippingAsBilling}
        setShippingAsBilling={setShippingAsBilling}
        isPointRelais={isPointRelais}
        setIsPointRelais={setIsPointRelais}
      >
        <ValidateButton onClick={handleClick}>Valider</ValidateButton>
      </FormAdresse>
    </Pannel>
  )
}
