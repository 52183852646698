import styled from "styled-components"
import { Address as AddressBase } from "../../../PageDashboard/Addresses/Address"
import { CommonSubtitle, CommonParagraph } from "./../../../../common/Texts"
import { Popin as PopinBase } from "./../../../../common/Popin"
import { Button } from "../../../../common/Button"

export const Address = styled(AddressBase)`
  ${({ isSelected }) =>
    isSelected
      ? "border: 2px solid #C4AB88;"
      : "border: 1px solid #F2F2F2"};
  height: 100%;
  background-color: transparent;
  line-height: 30%;
  display: flex;
  cursor: pointer;
  &.mobile {
    line-height: 40%;
    min-width: 100%;
    width: 300px;
  }
`
export const AdressList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 10px;
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  gap: 20px;
  &.mobile {
    height: 80%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
`
export const Wrapper01 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: fit-content;
  &.mobile {
    flex-direction: column;
  }
`

export const Subtitle = styled(CommonSubtitle)`
  font-size: 20px;
`

export const RecapInfo = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const Row = styled.div`
  display: flex;
  height: 70px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  align-items: center;
`

export const AddressBtn = styled(Button)`
  margin: 10px 0;
  align-self: center;
  justify-content: center;
  width: auto;
`

export const Title = styled(CommonParagraph)`
  width: 70%;
  margin: 0 5px;
  line-height: 1rem;
`

export const Text = styled.div``

export const Popin = styled(PopinBase)`
  left: 0px;
  &.mobile {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
`
