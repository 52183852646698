import React, { useEffect, useState } from 'react';
import { Up2PayElementView } from './Up2PayElement.view';
import { ErrorMessage } from "../../../../common/Texts"
import { Spinner } from '../../../../common/Spinner';
import axios from 'axios';
import { fixPrice } from '../../../../../helper/fixedPrice';
import { parsePhoneNumber } from 'react-phone-number-input';

interface Props {
    email: string
    orderNumber: string
    total: number
    billingAddress: any
    numberOfItems: number
}

export function Up2PayElementContainer({ email, orderNumber, total, numberOfItems, billingAddress }: Props) {
    const [data, setData] = useState(false)
    const [error, setError] = useState(false)
    const amount = total * 100



    useEffect(() => {
        const phoneNumber = parsePhoneNumber(billingAddress.phone)
        const phoneCountryCode =  phoneNumber.countryCallingCode
        const phoneNationalNumber = phoneNumber.nationalNumber

        axios({
            method: 'post',
            url: process.env.GATSBY_SALEOR_URL + '/plugins/lingemat.plugins.payments.up2pay/webhooks/calculate_hash/',
            data: {
                montant: Number(fixPrice(amount)).toString(),
                ref: orderNumber,
                email: email,
                numberOfItems: numberOfItems.toString(),
                firstname: billingAddress.firstName,
                lastname: billingAddress.lastName,
                streetAddress1: billingAddress.streetAddress1,
                streetAddress2: billingAddress.streetAddress2,
                postalCode: billingAddress.postalCode,
                city: billingAddress.city,
                countryCode: billingAddress.country.code,
                phone: phoneNationalNumber,
                phoneCountryCode : phoneCountryCode
            }
        })
            .then(response => {
                setData(response.data)
            })
            .catch(err => { setError(err) })
    }, [])

    if (!data && !error) return <Spinner />
    if (error) return <ErrorMessage>{error}</ErrorMessage>
    return <Up2PayElementView data={data} />
}
