import { useCheckout } from "@saleor/sdk"
import React, { useEffect, useState } from "react"
import { useCartWithTotal } from "../../../../hooks/useCartWithTotal"
import { PopUpThanksForOrder } from "../../PopUpThanksForOrder"
import { isDomAvailable } from "./../../../../lib"
import { Wrapper } from "./CheckoutFinal.style"

interface Props {
  className?: string
  onClick?: () => void
}

export function CheckoutFinalView({ className }: Props) {
  const { items, removeItem } = useCartWithTotal()
  const { completeCheckout, payment } = useCheckout()
  const [checkoutFinishedError, setCheckoutFinishedError] = useState("")
  const [orderInfo, setOrderInfo] = useState()

  if (isDomAvailable()) {
    history.pushState(null, document.title, location.href)
    window.addEventListener("popstate", function () {
      history.pushState(null, document.title, location.href)
    })
  }

  useEffect(() => {
    ;(async () => {
      if (!payment) return null
      const response = await completeCheckout()
      const data = response.data

      if (response.dataError) {
        const dataError = response.dataError
        if (dataError.error) {
          const errors = dataError.error

          if (errors && errors[0].code !== "NO_LINES") {
            console.error("error completing checkout - CheckoutFinal", errors)
            setCheckoutFinishedError("Probleme de confirmation de la commande")
          } else if (errors[0].code == "NO_LINES") {
            items?.forEach(item => {
              removeItem(item.variant.id)
            })
            setOrderInfo(data.order)
            setCheckoutFinishedError("")
          }
        }
      } else {
        items?.forEach(item => {
          removeItem(item.variant.id)
        })
        setOrderInfo(data.order)
        setCheckoutFinishedError("")
      }
    })()
  }, [payment, items])

  return (
    <Wrapper className={className}>
      <PopUpThanksForOrder
        error={checkoutFinishedError}
        totalPrice={orderInfo?.total}
        orderNumber={orderInfo?.number}
        paymentStatus={orderInfo?.paymentStatus}
      />
    </Wrapper>
  )
}
