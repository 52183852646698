import { ICheckoutModelLine } from "@saleor/sdk/lib/helpers"
import React, {  } from "react"
import {
  Wrapper,
  ItemImage,
  ProductName,
  VariantName,
  Undiscount,
  Quantity,
  ColumnPrice,
  ColumnTextInfo,
  Price,
  WrapperProduct,
  ColumnVariant,
  RowInfo,
} from "./ProductRow.style"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { fixPrice } from "../../../../../helper/fixedPrice"
import { DynamicBrandName } from "../../../../common/DynamicBrandName"

interface Props {
  className?: string
  data: ICheckoutModelLine
  onChange?: Function
  value?: number
}

export function ProductRowView({ data, className = "" }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  return (
    <>
      {isMobile && (
        <Wrapper className={classNames}>
          <WrapperProduct>
            <ItemImage data={data.variant.product.thumbnail} />
            <ColumnTextInfo className={classNames}>
              <ColumnTextInfo>
                <DynamicBrandName productId={data.variant.product.id} />
                <RowInfo>
                  <Quantity>{data.quantity}</Quantity>
                  <ProductName className={isMobile && "mobile"}>
                    {data.variant.product.name}
                  </ProductName>
                </RowInfo>
              </ColumnTextInfo>
              {data.variant.attributes &&
                data.variant.attributes.map(attribute => (
                  <div key={attribute.id}>
                    {attribute.values &&
                      attribute.values.map(value => (
                        <VariantName key={value.name}>{value.name}</VariantName>
                      ))}
                  </div>
                ))}
            </ColumnTextInfo>
          </WrapperProduct>
          <ColumnPrice className={isMobile && "mobile"}>
            <Price>
              {fixPrice(data.variant.pricing.price.gross.amount)}
            </Price>
            {data.variant.pricing.onSale && (
              <Undiscount>
                {fixPrice(
                  data.variant.pricing.priceUndiscounted.gross.amount
                )}
              </Undiscount>
            )}
          </ColumnPrice>
        </Wrapper>
      )}
      {!isMobile && (
        <Wrapper className={classNames}>
          <WrapperProduct>
            <ItemImage data={data.variant.product.thumbnail} />
            <ColumnTextInfo>
              <DynamicBrandName productId={data.variant.product.id} />
              <ProductName className={isMobile && "mobile"}>
                {data.variant.product.name}
              </ProductName>
            </ColumnTextInfo>
          </WrapperProduct>
          <ColumnVariant>
            {data.variant.attributes &&
              data.variant.attributes.map(attribute => (
                <div key={attribute.id}>
                  {attribute.values &&
                    attribute.values.map(value => (
                      <VariantName key={value.name}>{value.name}</VariantName>
                    ))}
                </div>
              ))}
          </ColumnVariant>
          <Quantity>{data.quantity}</Quantity>

          <ColumnPrice className={isMobile && "mobile"}>
            <Price>
              {fixPrice(data.variant.pricing.price.gross.amount)}
            </Price>
            {data.variant.pricing.onSale && (
              <Undiscount>
                {fixPrice(
                  data.variant.pricing.priceUndiscounted.gross.amount
                )}
              </Undiscount>
            )}
          </ColumnPrice>
        </Wrapper>
      )}
    </>
  )
}
