import React, { useState } from "react"
import {
  Wrapper,
  BtnSelectInfo,
  CreateAccountForm,
  LoginForm,
  SelectChoice,
  SelectCheckinCheckout,
} from "./RegisterOrLogin.style"
import { useAuth } from "@saleor/sdk"
import { navigate } from "gatsby"
import { useEffect } from "react"
import {getAttributesFromUrl} from "./../../../utils/index"
import { StepTwo } from "./CreateAccountForm/StepTwo"

interface Props {
  className?: string
  redirectUrl?: string
  isCheckoutReady: boolean
  isCheckout: boolean
}

export function RegisterOrLoginView({
  className,
  isCheckoutReady,
  redirectUrl = "/",
  isCheckout = false,
}: Props) {
  const types = ["Connexion", "Nouveau compte"]
  const [active, setActive] = useState(types[0])
  const { authenticated, user } = useAuth()

  useEffect(() => {
    // si on n'est pas dans le checkout la redirection peut ce faire après connection
    if (authenticated && user && !isCheckout && user?.addresses.length>0) {
      navigate(redirectUrl)
    }
    // si checkout la redirection peut ce faire après connection + init du checkout
    if (authenticated && user && isCheckoutReady && user?.addresses.length>0) {
      navigate(redirectUrl)
    }
  }, [authenticated, isCheckoutReady])

  // const attributes = getAttributesFromUrl()
  // // if url contains email and token parameters
  // if(attributes.length){
  //   let args = {}
  //   attributes.map(item=>{
  //     args[item.slug] = item.values[0]
  //   })
  //   return <StepTwo email={decodeURIComponent(args.email)} token={args.token}/>
  // }

  return (
    <Wrapper className={className}>
      <SelectCheckinCheckout>
        {types.map(type => (
          <BtnSelectInfo
            key={type}
            className={active === type && "active"}
            onClick={() => setActive(type)}
          >
            {type}
          </BtnSelectInfo>
        ))}
      </SelectCheckinCheckout>
      <SelectChoice>
        {active == "Nouveau compte" && <CreateAccountForm />}
        {active == "Connexion" && <LoginForm redirectUrl={redirectUrl} />}
      </SelectChoice>
    </Wrapper>
  )
}
