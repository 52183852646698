import styled from "styled-components"
import { Input as InputBase } from "../Input"
import { Button as ButtonBase } from "../Button"
import { CommonParagraph } from "../Texts"
import { SelectCountry } from "./../SelectCountry"
import PhoneInput from "react-phone-number-input"

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
`

export const Input = styled(InputBase)`
  width: 48%;
  box-sizing: border-box;
  margin: 2% 1%;
  &.mobile {
    font-size: 1rem;
    width: 98%;
    height: 2em;
  }
`
export const PhoneInputStyle = styled(PhoneInput)`
  width: 48%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  padding: 20px;
  margin: 2% 1%;
  input {
    border: none;
    font-family: ${({ theme }) => theme.typography.body.fontFamily};
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    &:focus {
      outline: none;
    }
  }
`

export const Select = styled.select`
  width: 48%;
  box-sizing: border-box;
  margin: 2% 1%;
  &.mobile {
    font-size: 0.9rem;
    width: 98%;
    color: black;
    padding: 9px;
  }
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  padding: 20px;
  &:focus {
    outline: none;
  }
`

export const SelectCountryFixed = styled(SelectCountry)`
  width: 48%;
  &.mobile {
    width: 98%;
  }
`

export const Submit = styled(ButtonBase)`
  width: 30%;
  margin: 2% auto;
  display: flex;
  justify-content: center;
  text-align: center;
  &.mobile {
    min-width: 120px;
  }
`

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px;
  &.mobile {
    padding: 0;
  }
`

export const LabelFormResult = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
`
export const ListErrors = styled(LabelFormResult)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FormSent = styled(CommonParagraph)`
  align-items: center;
  margin: 15px;
`

export const ErrorFormSent = styled(FormSent)`
  color: ${({ theme }) => theme.palette.error.main};
`
export const Error = styled(ErrorFormSent)`
  margin: 5px 0;
`
