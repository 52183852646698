import { useCheckout, useCart } from "@saleor/sdk"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { CHECKOUT_STEPS } from "../../../../core/config"
import { getActiveStep } from "../../../../helper/getActiveStep"
import { getNextStep } from "../../../../helper/getNextStep"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { Button } from "../../../common/Button"
import { ErrorMessage } from "../../../common/Texts"
import {
  InfoWrapper,
  ItemWrapper,
  Label,
  ListPaymentGateway,
  Row,
  SousWrapper,
  StyledIcon,
  Wrapper,
  Logo,
  LogoWrapper,
  TextContainer,
  TextWrapper,
  Subtitle,
} from "./CheckoutPayment.style"
import { fixPrice } from "../../../../helper/fixedPrice"
import { enumFrom } from "../../../common/ImageWithLinkOrNot"
import { useHeaderQuery } from "../../../common/Header/Header.query"
import { Up2PayElement } from "./Up2PayElement"

export function CheckoutPaymentView() {
  const isMobile = useDetectDevice()
  const [paymentGatewayErrors, setPaymentGatewayErrors] = useState("")
  const [selectedGateway, setSelectedGateway] = useState({ id: "" })
  const currentStepInfo = getActiveStep(CHECKOUT_STEPS)
  const nextStateInfo =
    getNextStep(currentStepInfo, CHECKOUT_STEPS) || currentStepInfo
  const { checkout, availablePaymentGateways, createPayment } = useCheckout()
  const { items, totalPrice } = useCart()

  const getNumberOfItems = () => {
    let res = 0
    items?.map(item => {
      res += item.quantity
    })
    return res
  }

  const handleSubmitPaymentSuccess = async () => {
    setPaymentGatewayErrors("")
  }

  const handlePaymentGatewayError = () => {
    setPaymentGatewayErrors("Paiement impossible")
  }

  // Bank check and/or bank transfer process
  const handleBankCheckOrTransferProcessPayment = async () => {
    const { dataError } = await createPayment({
      gateway: selectedGateway.id,
      token: "not-charged",
      returnUrl: `${window.location.origin}/app${nextStateInfo.link}`,
    })
    const error = dataError?.error
    if (!error) {
      handleSubmitPaymentSuccess()
      navigate(`/app/checkout/payment-final`)
    } else {
      handlePaymentGatewayError(error)
    }
  }

  function onClickPayment(id) {
    let selected = {}
    availablePaymentGateways?.map(item => {
      if (item.id == id) {
        selected = item
      }
    })
    setSelectedGateway(selected)
  }

  let total
  
  if (
    checkout?.shippingMethod?.name != "Livraison en point relais" &&
    checkout?.shippingMethod?.name != "Livraison à domicile" &&
    checkout?.shippingMethod?.name != "EUROPE A - COLISSIMO"
  ) {
    total = totalPrice?.net.amount
  } else {
    total = totalPrice?.gross.amount
  }
  console.log("totalPrice CHECKOUT PAYMENT ", total)

  function FormPaymentRender(selectedGateway: { id: string }) {
    if (!selectedGateway?.id) return <></>
    switch (selectedGateway.id) {
      case gatewayType.up2pay:
        return (
          <Up2PayElement
            email={checkout.email}
            total={total}
            orderNumber={checkout.token}
            billingAddress={checkout.billingAddress}
            numberOfItems={getNumberOfItems()}
          />
        )
      case gatewayType.checkOrTransfer:
        return (
          <CheckOrTransferInfo
            processPayment={handleBankCheckOrTransferProcessPayment}
            onClickOutside={() => {
              setSelectedGateway({ id: "true" })
            }}
          />
        )
      default:
        break
    }
  }

  const getGatewayName = item => {
    switch (item.id) {
      case gatewayType.checkOrTransfer:
        return "Payer par chèque ou virement"
      case gatewayType.stripe:
        return "Payer par carte"
      case gatewayType.up2pay:
        return "Payer par Carte Bancaire, Paylib ou Paypal"
      default:
        return null
    }
  }

  function itemGateway(item: any): JSX.Element {
    let classNames = ""
    if (selectedGateway.id == item.id) classNames = "selected"
    if (isMobile) classNames = classNames + " mobile"
    return (
      <div
        key={uuidv4()}
        style={{ width: "fit-content", marginBottom: "10px" }}
      >
        <ItemWrapper
          onClick={() => {
            onClickPayment(item.id)
          }}
          className={classNames}
          key={uuidv4()}
        >
          <Label>{getGatewayName(item)}</Label>
        </ItemWrapper>
        <div>
          {item.id == selectedGateway.id && FormPaymentRender(selectedGateway)}
        </div>
      </div>
    )
  }

  return (
    <Wrapper className={isMobile && "mobile"}>
      <ListPaymentGateway className={isMobile && "mobile"}>
        {!availablePaymentGateways && <ErrorMessage>Vous devez sélectionner une adresse de livraison</ErrorMessage>}
        {availablePaymentGateways &&
          availablePaymentGateways.map(item => itemGateway(item))}
      </ListPaymentGateway>
      <ErrorMessage>{paymentGatewayErrors}</ErrorMessage>
    </Wrapper>
  )
}

enum gatewayType {
  stripe = "saleor.payments.stripe",
  braintree = "mirumee.payments.braintree",
  checkOrTransfer = "mirumee.payments.dummy",
  transfer = "mirumee.payments.dummy",
  up2pay = "lingemat.plugins.payments.up2pay",
}

interface Props {
  processPayment?: any
  onClickOutside?: Function
}

export function CheckOrTransferInfo({
  processPayment,
  onClickOutside = () => {},
}: Props) {
  const isMobile = useDetectDevice()
  const [check, setChecked] = React.useState(false)
  const [transfer, setTransfered] = React.useState(false)

  const handleCheck = () => {
    setChecked(!check)
    if (transfer) {
      setTransfered(false)
    }
  }

  const handleTransfer = () => {
    setTransfered(!transfer)
    if (check) {
      setChecked(false)
    }
  }

  const processPaymentAndQuit = () => {
    metaPayment()
    processPayment()
    onClickOutside()
  }
  const dataLogo = useHeaderQuery()

  const metaPayment = () => {
    if (check) {
      // console.log("cheque")
    }
    if (transfer) {
      // console.log("virement")
    }
  }

  return (
    <InfoWrapper>
      <SousWrapper className={isMobile && "mobile"}>
        <LogoWrapper>
          <Logo
            data={dataLogo.strapiGmHeader.BandeauHeader.Logo}
            from={enumFrom.STRAPI}
          />
          <StyledIcon icon="cross" onClick={onClickOutside} />
        </LogoWrapper>
        <TextWrapper>
          <TextContainer>
            <Subtitle>
              <Checkbox
                label="Je paye par chèque"
                value={check}
                onChange={handleCheck}
              />
            </Subtitle>
            <Row>
              Pour le paiement par chèque, veuillez envoyer votre chèque à
              l'adresse suivante :
            </Row>
            <Row>
              <b>
                GRANDES MARQUES 210, Avenue de l’Industrie 31660 BESSIERES
                FRANCE
              </b>
            </Row>
            <Row>Possibilité de payer en 3 fois par chèque.</Row>
            <Row>
              <b>
                Merci de contacter notre service clientèle au : 05 82 95 08 87
              </b>
            </Row>
          </TextContainer>
          <TextContainer>
            <Subtitle>
              <Checkbox
                label="Je paye par virement"
                value={transfer}
                onChange={handleTransfer}
              />
            </Subtitle>
            <Row>
              Pour le paiement par virement, veuillez effectuer votre virement
              sur le compte suivant:
            </Row>
            <Row>IBAN: FR76 1310 6005 0020 0000 8423 728</Row>
            <Row>SWIFT: AGRIFRPP831</Row>
            <Row>Code Etablissement: 13 106</Row>
            <Row>Code Guicher: 00 500</Row>
            <Row>Numéro de compte: 20000084237</Row>
            <Row>Clé RIB: 28</Row>
            <Row>Nom et adresse du titulaire:</Row>
            <Row>SARL MAT</Row>
            <Row>PARC ECONOMIQUE DU TRIANGLE</Row>
            <Row>210 AVENUE DE L'INDUSTRIE</Row>
            <Row>31 660 BESSIERES</Row>
          </TextContainer>
          {!check && !transfer && (
            <Button onClick={null}>Je choisis mon mode de paiement</Button>
          )}
          {(check || transfer) && (
            <Button onClick={processPaymentAndQuit}>Valider la commande</Button>
          )}
        </TextWrapper>
      </SousWrapper>
    </InfoWrapper>
  )
}

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label>
      {label}
      <input type="radio" checked={value} onChange={onChange} />
    </label>
  )
}
