import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useAuth, useCart } from "@saleor/sdk"
import { CommonWrapper } from "../../CommonWrapper"
import {
  Wrapper,
  Input,
  Error,
  Submit,
  Wrappers,
  WrapperButton,
  WrapperButton1,
  WrapperButton2,
  Link,
} from "./LoginForm.style"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { useCartWithTotal } from "../../../../hooks/useCartWithTotal"


interface Props {
  className?: string
  redirectUrl?: string
}

interface IFormInput {
  email: string
  password: string
}

export function LoginFormView({ className, redirectUrl = "/" }: Props) {
  const { addItems } = useCartWithTotal()
  const [error, setError] = useState("")
  const { signIn } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>()

  const handleSignIn = async (email: string, password: string) => {
    const { data, dataError } = await signIn(email, password)
    if (dataError) {
      setError("Impossible de se connecter")
    } else if (data) {
      // Saleor SDK use localStorage to save cart before sync with saleor backend, I want to retrieve items when user sign in if he doesn't have a cart on backend
      let data_checkout_tmp = localStorage.getItem('data_checkout_tmp')
      if (data_checkout_tmp) {
        let obj_tmp = JSON.parse(data_checkout_tmp)
        let items = obj_tmp.lines.map(item => ({ variantId: item.variant.id, quantity: item.quantity }))
        await addItems(items)
        localStorage.removeItem('data_checkout_tmp')
      }
      // redirection avec lacation pour contourner le bug de panier
      window.location.href = redirectUrl
    }
    return data
  }

  const onSubmit = (data: IFormInput) => {
    handleSignIn(data.email, data.password)
  }
  const isMobile = useDetectDevice()
  const FormView = () => {
    return (
      <>
        <Input
          className={isMobile && "mobile"}
          placeholder="Email"
          type="email"
          {...register("email", {
            required: true,
          })}
        />
        {errors.email && <Error>Champs incorrect</Error>}
        <Input
          className={isMobile && "mobile"}
          placeholder="Mot de passe"
          type="password"
          {...register("password", {
            required: "Vous devez saisir un mot de passe",
          })}
        />
        {errors.password && <Error>{errors.password.message}</Error>}
        {error && <Error>{error}</Error>}
        <WrapperButton className={isMobile && "mobile"}>
          <WrapperButton1>
            <Link link="/app/demand-password">Mot de passe oublié</Link>
          </WrapperButton1>
          <WrapperButton2>
            <Submit type="submit">SUIVANT</Submit>
          </WrapperButton2>
        </WrapperButton>
      </>
    )
  }

  return (
    <Wrapper className={className}>
      <CommonWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrappers className={isMobile && "mobile"}>
            <FormView />
          </Wrappers>
        </form>
      </CommonWrapper>
    </Wrapper>
  )
}
