import React, { useEffect, useState } from "react"
import { useCheckout } from "@saleor/sdk"
import {
  SelectorWrapper,
  FormWrapper,
  MapORFormDomicileArea,
  RadioButton,
  StyledFormAfficherMap,
  Title,
} from "./FormulaireLivraison.style"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { FormAfficherMap } from "./FormAfficherMap"
import { CustomerShippingAddresses } from "./CustomerShippingAddresses"
import { v4 as uuidv4 } from "uuid"
import { Components } from "react-markdown/src/ast-to-react"

interface PropFormAdresse {
  setIsPointRelais: Function
  isPointRelais: boolean
  shippingAsBilling: boolean
  children: Components
}

export const FormAdresse = ({
  shippingAsBilling,
  isPointRelais,
  setIsPointRelais,
  children,
}: PropFormAdresse) => {
  const { availableShippingMethods, setShippingMethod, checkout } =
    useCheckout()
  const isMobile = useDetectDevice()
  shippingAsBilling = false
  const [adressOrMapVisible, setAdressOrMapVisible] = useState(false)

  useEffect(() => {
    if (!adressOrMapVisible && checkout?.shippingMethod) {
      onChange(checkout.shippingMethod)
    }
  }, [checkout])

  const onChange = item => {
    if (
      item.name == "FRANCE COLISSIMO RELAIS" ||
      item.name == "Livraison en point relais"
    ) {
      setIsPointRelais(true)
    } else {
      setIsPointRelais(false)
    }
    setShippingMethod(item.id)
    setAdressOrMapVisible(true)
  }

  return (
    <>
      <FormWrapper className={isMobile && "mobile"}>
        <Title className={isMobile && "mobile"}>
          Veuillez choisir votre mode de livraison
        </Title>
        <SelectorWrapper className={isMobile && "mobile"}>
          {availableShippingMethods &&
            availableShippingMethods.map(item => {
              return (
                <RadioButton
                  key={uuidv4()}
                  name="shippingMethod"
                  type="button"
                  checked={checkout?.shippingMethod?.id == item.id}
                  value={item.id}
                  onClick={() => onChange(item)}
                >
                  {item.name == "Livraison en point relais" &&
                    "Livraison en point relais"}
                  {item.name != "Livraison en point relais" &&
                    "Livraison à domicile"}
                </RadioButton>
              )
            })}
        </SelectorWrapper>
        {adressOrMapVisible && (
          <MapORFormDomicileArea>
            {!isPointRelais && (
              <CustomerShippingAddresses
                shippingAsBilling={shippingAsBilling}
              />
            )}
            {isPointRelais && (
              <StyledFormAfficherMap>
                <FormAfficherMap postalCode={""} />
              </StyledFormAfficherMap>
            )}
          </MapORFormDomicileArea>
        )}
      </FormWrapper>
      <>{adressOrMapVisible && children}</>
    </>
  )
}
