import { CountryCode, useCreateUserAddress } from "@saleor/sdk"
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { v4 as uuidv4 } from "uuid"
import {
  Error,
  Input,
  LabelFormResult,
  ListErrors,
  PhoneInputStyle,
  Select,
  SelectCountryFixed,
  Submit,
  Wrapper,
} from "./CreateAddressForm.style"
import { useLazyQuery } from "@apollo/react-hooks"
import { GET_COUNTRY_AREAS } from "./../RegisterOrLogin/CreateAccountForm/UniqueStepWithoutConfirmation/UniqueStepWithoutConfirmation.query"
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input"
interface Props {
  redirectUrl?: string
  closeAction: Function
  className?: string
}

interface IFormInput {
  firstName: string
  lastName: string
  country: CountryCode
  countryArea?: string
  city: string
  streetAddress1: string
  streetAddress2?: string
  postalCode: string
  companyName: string
  phone: string
}

export function CreateAddressFormView({
  className,
  closeAction,
  redirectUrl = "/app/profile",
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setFocus,
    control,
  } = useForm<IFormInput>()
  const [createUserAddress, resCreateUserAddress] = useCreateUserAddress()
  const [customError, setCustomError] = useState("")
  const [getCountryAreas, { called, loading, data: dynamicGetCountryAreas }] =
    useLazyQuery(GET_COUNTRY_AREAS)
  const [countryAreas, setCountryAreas] = useState(null)
  const [postalCodeRegex, setPostalCodeRegex] = useState("/.*/gmi")
  const watchCountry = watch("country")
  const isMobile = useDetectDevice()

  useEffect(() => {
    if (watchCountry || postalCodeRegex == "/.*/gmi") {
      getCountryAreas({ variables: { countryCode: watchCountry } })
    }
  }, [watchCountry, postalCodeRegex])

  useEffect(() => {
    if (!loading && called) {
      if (dynamicGetCountryAreas) {
        setPostalCodeRegex(
          dynamicGetCountryAreas.addressValidationRules.postalCodeMatchers
        )
        setCountryAreas(
          dynamicGetCountryAreas.addressValidationRules.countryAreaChoices
        )
      }
      if (dynamicGetCountryAreas?.error) {
        setCountryAreas(null)
      }
    }
  }, [dynamicGetCountryAreas, loading, called])

  useEffect(() => {
    if (resCreateUserAddress.data) {
      closeAction()
      navigate(redirectUrl)
    }
    if (resCreateUserAddress.error) {
      setCustomError(
        "Erreur lors de la creation de l'adresse. Veuillez vérifier les informations saisies."
      )
    }
  }, [resCreateUserAddress])

  useEffect(() => {
    if (errors.lastName) setFocus("lastName")
    else if (errors.firstName) setFocus("firstName")
    else if (errors.streetAddress1) setFocus("streetAddress1")
    else if (errors.postalCode) setFocus("postalCode")
    else if (errors.city) setFocus("city")
    else if (errors.phone) setFocus("phone")
  }, [setFocus, errors])

  const onSubmit = async (data: IFormInput) => {
    setCustomError("")
    setFocus("firstName")
    let newAddress = await createUserAddress({
      input: {
        firstName: data.firstName,
        lastName: data.lastName,
        country: data.country,
        countryArea: data.countryArea,
        city: data.city,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        postalCode: data.postalCode,
        companyName: data.companyName,
        phone: data.phone,
      },
    })
  }

  const getSelectCountryArea = () => {
    if (countryAreas?.length > 0) {
      return (
        <Select
          className={classMobile}
          placeholder="Etat/Province *"
          {...register("countryArea", {
            required: false,
          })}
          defaultValue={countryAreas[0].raw}
        >
          {countryAreas.map(item => (
            <option key={uuidv4()} value={item.raw}>
              {item.verbose}
            </option>
          ))}
        </Select>
      )
    }
    return null
  }

  let classMobile = isMobile ? "mobile" : ""
  return (
    <Wrapper className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          className={classMobile}
          placeholder="Nom *"
          {...register("lastName", {
            required: true,
          })}
        />
        <Input
          className={classMobile}
          placeholder="Prénom *"
          {...register("firstName", {
            required: true,
          })}
        />
        <Input
          className={classMobile}
          placeholder="Entreprise"
          {...register("companyName", {
            required: false,
          })}
        />
        <Input
          className={classMobile}
          placeholder="Numéro et rue *"
          {...register("streetAddress1", { required: true })}
        />
        <Input
          className={classMobile}
          placeholder="Informations complémentaires"
          {...register("streetAddress2")}
        />
        <Input
          className={classMobile}
          placeholder="Code Postal *"
          {...register("postalCode", {
            required: true,
            pattern: {
              value: new RegExp(postalCodeRegex),
              message: "Code postal incorrect",
            },
          })}
        />
        <Input
          className={classMobile}
          placeholder="Ville *"
          {...register("city", { required: true })}
        />
        <SelectCountryFixed register={register} />
        {getSelectCountryArea()}
        <Controller
          name="phone"
          control={control}
          rules={{
            validate: value => isValidPhoneNumber(`${value}`),
          }}
          render={({ field: { onChange, value } }) => (
            <PhoneInputStyle
              id="phone"
              name="phone"
              placeholder="Téléphone *"
              defaultCountry="FR"
              value={formatPhoneNumberIntl(value)}
              onChange={onChange}
            />
          )}
        />
        <Submit type="submit">VALIDER</Submit>
      </form>
      <LabelFormResult>
        <ListErrors>
          {errors.lastName && <Error>Nom incorrect</Error>}
          {errors.firstName && <Error>Prénom incorrect</Error>}
          {errors.streetAddress1 && <Error>Adresse incorrecte</Error>}
          {errors.postalCode && <Error>Code postal incorrect</Error>}
          {errors.city && <Error>Ville incorrecte</Error>}
          {errors.phone && <Error>Téléphone incorrect</Error>}
          {customError && <Error>{customError}</Error>}
        </ListErrors>
      </LabelFormResult>
    </Wrapper>
  )
}
