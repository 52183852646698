import React from "react"
import { Wrapper, Top, Bottom, Logo, Title } from "./PagePasswordAction.style"
import { enumFrom } from "../../common/ImageWithLinkOrNot"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { usePasswordActionQuery } from "./PagePasswordAction.query"
import { ChangePassword } from "./ChangePassword"
import { ResetPassword } from "./ResetPassword"
import { DemandNewPassword } from "./DemandNewPassword"
import { Link } from "../../common/Link"

interface Props {
  className?: string
  title: string
  type: string
}

export function PagePasswordActionView({ className, title, type }: Props) {
  const data = usePasswordActionQuery()
  const isMobile = useDetectDevice()
  const classMobile = isMobile ? "mobile" : ""

  const getChildren = () => {
    if (type == "change") return <ChangePassword />
    if (type == "reset") return <ResetPassword />
    if (type == "demand") return <DemandNewPassword />
    return null
  }

  return (
    <Wrapper className={className}>
      <Top>
        <Link link={process.env.GATSBY_FRONTEND_URL}>
          <Logo
            data={data.strapiGmHeader.BandeauHeader.Logo}
            from={enumFrom.STRAPI}
          />
        </Link>
      </Top>
      <Bottom className={classMobile}>
        <Title className={classMobile}>{title}</Title>
        {getChildren()}
      </Bottom>
    </Wrapper>
  )
}
