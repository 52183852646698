import React, { useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { GET_PRODUCTS_AND_VARIANTS_BY_SKUS } from "../../TemplateProduct/ProductSet/ProductSet.queries"
import { Spinner } from "./../../../common/Spinner"
import { ProductListView } from "./ProductList.view"
import { useCartWithTotal } from "../../../../hooks/useCartWithTotal"

export function ProductListContainer() {
  const { items, loaded } = useCartWithTotal()
  const [lines, setLine] = useState([])

  var metaProducts = []
  let skus = lines?.map(item => (item.variant.sku))
  const { loading, error, data } = useQuery(GET_PRODUCTS_AND_VARIANTS_BY_SKUS, {
    variables: { skus: skus },
  })
  if (!error) {
    metaProducts.push(data?.productVariants.edges[0].node.product.metadata)
  }

  useEffect(() => {
    if (items.length >= lines.length)
      setLine([...items])

  }, [items])

  if (!loaded || items.length == 0) {
    return <Spinner />
  }

  return (
    <ProductListView
      items={lines}
      totalPriceInitial={calculTotalInitialPrice(lines)}
      metaProducts={metaProducts}
    />
  )
}


const calculTotalInitialPrice = (linesData) => {
  let totalPriceInitial = 0
  let totalPriceInitialnet = 0
  let currency = ""
  linesData?.map(item => {
    currency = item.variant.pricing?.priceUndiscounted?.gross.currency
    totalPriceInitial += item.variant.pricing?.priceUndiscounted?.gross.amount * item.quantity
    totalPriceInitialnet += item.variant.pricing?.priceUndiscounted?.net.amount * item.quantity
  })

  return {
    "gross": {
      "amount": totalPriceInitial,
      "currency": currency,
    },
    "net" : {
      "amount": totalPriceInitialnet
    }
  }

}
