import React, { useState } from "react"

import {
  ZipCodeWrapper,
  ZipcodeInput,
  ShowMapButton,
  Test,
} from "./FormulaireLivraison.style"
import { SelectionPointRelais } from "./SelectionPointRelais"
import { isDomAvailable } from "../../../../lib"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "../../../common/Texts"
import { IFormInput } from "./FormulaireLivraison.view"
import { unselectActivePointLivraison } from "../../../../entities/PointLivraison/PointLivraison.reducer"
import { useDispatch } from "react-redux"

interface Props {
  postalCode: any
}
export const FormAfficherMap = ({ postalCode }: Props) => {
  const [displayMap, setDisplayMap] = useState(true)
  const [customError, setCustomError] = useState("")
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>()

  const dispatch = useDispatch()

  const onSubmit = (data: { zipcode: string; city: string }) => {
    setCustomError("")
    dispatch(unselectActivePointLivraison())
    if (data.zipcode == "" && data.city == "") {
      setCustomError("Champs incorrect")
      return false
    }
    setDisplayMap(false)
    setDisplayMap(true)
  }

  return (
    <>
      <ZipCodeWrapper onSubmit={handleSubmit(onSubmit)}>
          <ZipcodeInput
            placeholder="Code postal"
            {...register("zipcode", {
              required: true,
              min: 5,
              pattern: /^[0-9]{5}$/i,
            })}
            defaultValue={postalCode}
          />
          <ShowMapButton type="submit"> Rechercher </ShowMapButton>
      </ZipCodeWrapper>
        {(errors.city || errors.zipcode) && <Test>Champs incorrect</Test>}
        {customError && <ErrorMessage>{customError}</ErrorMessage>}
      {displayMap && isDomAvailable() && (
        <SelectionPointRelais
          zipCode={getValues("zipcode")}
          city={getValues("city")}
        />
      )}
    </>
  )
}
